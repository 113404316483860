import React, { RefObject, forwardRef, useEffect, useRef } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useMeasure, useUpdateEffect } from 'react-use';
import { Nullable } from 'src/types/nullable.type';
import { Editor } from '@tiptap/core';
import { functionNoop } from 'src/utils/function/noop';
import classNames from 'classnames';
import { Coords, useEditorStore } from '../../editor-store';
import { NeueBasicTextEditor } from '../neue-basic-text-editor';
import { Block, BlockCaption } from '../../types';
import { SharedExtensions } from 'src/document-editor/extensions/shared-extensions';
import { isTextEmpty } from '../../mutual-action-plans/components/action-item';
import { useNeueLayoutMode } from '../../helpers/neue-layout-mode.hook';
import { usePreviousValue } from 'src/utils/react/previous-value.hook';
import { NeueTextMenu } from 'src/document-editor/neue-text-menu';

export interface CaptionWidgetProps {
  caption?: BlockCaption;
  isRenderModeEditor: boolean;
  blockId: Block['id'];
  onChange: (caption: BlockCaption) => void;
  show: boolean;
  selectionCoords: Nullable<Coords>;
  selected: boolean;
  blockElementRef: React.MutableRefObject<HTMLDivElement | undefined> | RefObject<HTMLDivElement>;
}

const FLOAT_ANIMATION_CONFIG = {
  mass: 1,
  tension: 400,
  friction: 40,
};

export const CaptionWidget = forwardRef<HTMLDivElement, CaptionWidgetProps>(
  ({ caption, blockId, isRenderModeEditor, selected, show, onChange, blockElementRef, selectionCoords }, ref) => {
    const openLinkMenuRef = useRef(functionNoop);
    const focusBySelectionCoordsRef = useRef(false);
    const [containerElementRef, containerElementRect] = useMeasure();
    const textEditorContainerElementRef = useRef<HTMLDivElement>(null);
    const editorRef = useRef<Nullable<Editor>>(null);
    const captionIsInitiallyEnabledRef = usePreviousValue(caption?.enabled ?? false);

    const isHeadingSetRef = useRef(false);
    const { layoutMode } = useNeueLayoutMode();

    const selectBlock = useEditorStore((state) => state.selectBlock);

    const editorHeight = show ? containerElementRect.height + 24 : 0;

    const onInputFocus = () => {
      selectBlock(blockId);
    };

    const [animationStyle, animate] = useSpring(() => {
      if (!show) {
        return {
          config: FLOAT_ANIMATION_CONFIG,
          height: 0,
        };
      }

      return { config: FLOAT_ANIMATION_CONFIG, height: editorHeight };
    }, [containerElementRect.height]);

    const expandWithAnimation = () => {
      animate({ height: editorHeight });
    };

    const narrowWithAnimation = () => {
      animate({ height: 0 });
    };

    useUpdateEffect(() => {
      if (!editorRef.current || !selectionCoords || !caption?.enabled) {
        return;
      }
      const pos = selectionCoords
        ? editorRef.current.view.posAtCoords({ left: selectionCoords.x, top: selectionCoords.y })
        : null;
      if (pos) {
        focusBySelectionCoordsRef.current = true;
        editorRef.current.commands.focus(pos.pos);
      } else {
        editorRef.current.commands.focus('end');
      }
    }, [editorRef.current, selectionCoords, caption?.enabled]);

    useEffect(() => {
      if (!blockElementRef.current) {
        return;
      }

      if (show) {
        expandWithAnimation();
      } else {
        narrowWithAnimation();
      }
      blockElementRef.current.setAttribute('style', `height:calc(100% - ${editorHeight}px)`);
    }, [show, editorHeight, blockElementRef]);

    useUpdateEffect(() => {
      if (!editorRef.current || !blockElementRef.current || !caption) {
        return;
      }
      if (caption.enabled !== captionIsInitiallyEnabledRef) {
        if (caption.enabled) {
          setTimeout(() => {
            editorRef.current?.commands.focus('end');
            blockElementRef.current?.classList.add('rounded-b-none');
          }, 300);
        } else {
          blockElementRef.current.classList.remove('rounded-b-none');
        }
      }
    }, [editorRef.current, caption?.enabled, captionIsInitiallyEnabledRef, blockElementRef]);

    if (!caption) {
      return null;
    }

    return (
      <animated.div
        ref={ref}
        className={classNames('relative overflow-hidden transition-opacity max-h-full', {
          'opacity-0': !show,
          'delay-200 opacity-100': show,
        })}
        style={animationStyle}
      >
        <div
          className='my-3 mx-4'
          ref={(el) => {
            if (el) {
              containerElementRef(el);
            }
          }}
        >
          <>
            <div
              ref={textEditorContainerElementRef}
              className='ignore-block-events'
              onClick={(e) => e.stopPropagation()}
            >
              <NeueBasicTextEditor
                content={caption.text}
                selected={selected}
                editable={isRenderModeEditor}
                externalExtensions={SharedExtensions}
                textPlaceholder='Add caption'
                enableVisitingUrlByCmdClick
                cursorClassname={isRenderModeEditor ? 'cursor-text' : 'cursor-default'}
                preventEnterPress
                openLinkPanelRef={openLinkMenuRef}
                // canReceiveUpdates
                editorClassName={classNames('neue-text-editor caption', {
                  web: layoutMode === 'web',
                  mobile: layoutMode !== 'web',
                })}
                onFocus={onInputFocus}
                onTransaction={(editor) => {
                  if (editor.isEmpty && editor.isActive('paragraph') && !isHeadingSetRef.current) {
                    isHeadingSetRef.current = true;
                    editor.commands.setHeading({ level: 5 });
                  }
                }}
                onUpdate={(content: string) => {
                  onChange({ text: content, enabled: true });
                }}
                onCreate={(editor) => {
                  if (isTextEmpty(caption.text)) {
                    // Disabling this temporarily because it's causing
                    // tiptap/prosemirror autoscrolls to the widget on load.
                    // editor.commands.setHeading({ level: 5 });
                  }
                  editorRef.current = editor;
                }}
              />
            </div>
            {isRenderModeEditor && editorRef.current && (
              <NeueTextMenu
                verticalAlignment='center'
                containerRef={textEditorContainerElementRef}
                editor={editorRef.current}
                openLinkPanelRef={openLinkMenuRef}
                usage='caption'
                selected={selected}
                onSetVerticalAlignment={functionNoop}
                onSetHorizontalAlignment={functionNoop}
              />
            )}
          </>
        </div>
        <div
          className={classNames('absolute inset-0 rounded-2xl transition bg-transparent z-20', {
            hidden: !isRenderModeEditor || selected,
            'pointer-events-none': selected,
          })}
        ></div>
      </animated.div>
    );
  }
);
